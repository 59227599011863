import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import { androidStoreUrl, iosStoreUrl } from '../../../utils/StoreUrl'
import { Link } from '../../elements/Link'
import { hoverOpacity } from '../../utils/hoverOpacity'
import { Grid } from '../Grid'

const FooterMenu: React.FC = () => (
  <Grid by={1} desktop={4}>
    <li>
      <ListHeader>RYDE PASS</ListHeader>
      <ItemList>
        <Item>
          <Link href="/#about" passHref>
            <ItemLink>RYDE PASSについて</ItemLink>
          </Link>
        </Item>
        <Item>
          <Link href="/products" passHref>
            <ItemLink>商品を探す</ItemLink>
          </Link>
        </Item>
        <Item>
          <Link href="/#inquiry" passHref>
            <ItemLink>お問い合わせ</ItemLink>
          </Link>
        </Item>
        <Item>
          <Link href="/faq" passHref>
            <ItemLink>よくあるご質問</ItemLink>
          </Link>
        </Item>
        <Item>
          <Link href="/terms" passHref>
            <ItemLink>利用規約</ItemLink>
          </Link>
        </Item>
        <Item>
          <Link href="/commerce_law" passHref>
            <ItemLink>特定商取引法に基づく表記</ItemLink>
          </Link>
        </Item>
        <Item>
          <Link href="/travel_law" passHref>
            <ItemLink>旅行業法に基づく表示</ItemLink>
          </Link>
        </Item>
      </ItemList>
    </li>
    <li>
      <ListHeader>RYDEサービス一覧</ListHeader>
      <ItemList>
        <Item>
          <ItemTextWrapper>
            <Link href="https://cycle.ryde-go.com" passHref>
              <ItemLink target="_blank" rel="noreferrer">
                RYDE CYCLE
              </ItemLink>
            </Link>
          </ItemTextWrapper>
          <StoreIconsWrapper>
            <Link href={iosStoreUrl('1447119108')}>
              <a target="_blank" rel="noreferrer" aria-label="AppStore">
                <Image
                  src="/svgs/appStoreIcon.svg"
                  width={13.05}
                  height={16}
                  alt="App Store"
                />
              </a>
            </Link>
            <Link href={androidStoreUrl('com.ryde_go.cycle')}>
              <a target="_blank" rel="noreferrer" aria-label="GooglePlay">
                <Image
                  src="/svgs/googlePlayIcon.svg"
                  width={14}
                  height={12.56}
                  alt="Google Play"
                />
              </a>
            </Link>
          </StoreIconsWrapper>
        </Item>
        <Item>
          <Link href="https://ryde-trip.com/" passHref>
            <ItemLink target="_blank" rel="noreferrer">
              TRIP by RYDE
            </ItemLink>
          </Link>
        </Item>
      </ItemList>
    </li>
    <li>
      <ListHeader>交通事業者の皆様へ</ListHeader>
      <ItemList>
        <Item>
          <Link href="https://form.run/@ryde-pass-contact" passHref>
            <ItemLink>お問い合わせ</ItemLink>
          </Link>
        </Item>
      </ItemList>
    </li>
    <li>
      <ListHeader>運営会社について</ListHeader>
      <ItemList>
        <Item>
          <Link href="https://ryde-inc.jp/" passHref>
            <ItemLink>RYDE株式会社</ItemLink>
          </Link>
        </Item>
        <Item>
          <Link href="https://ryde-inc.jp/privacy" passHref>
            <ItemLink>プライバシーポリシー</ItemLink>
          </Link>
        </Item>
      </ItemList>
    </li>
  </Grid>
)

const ListHeader = styled.h6`
  display: inline-flex;
  height: 20px;
  padding-bottom: 4px;
  color: ${theme.colors.textWhitePrimary};
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;

  ${Media.mobileTablet} {
    padding-bottom: 8px;
  }
`

const ItemList = styled.ul`
  padding-top: 20px;
  padding-bottom: 20px;

  ${Media.mobileTablet} {
    padding-top: 10px;
    padding-bottom: 24px;
  }
`

const Item = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 0;
  ${theme.typography.Button3}
  color: ${theme.colors.textWhiteSecondary};

  a {
    ${theme.typography.Button3}
    color: ${theme.colors.textWhiteSecondary};

    text-decoration: none;
  }
`

const ItemLink = styled.a`
  ${hoverOpacity()}
`

const ItemTextWrapper = styled.div`
  min-width: 100px;
  margin-right: 5px;
`

const StoreIconsWrapper = styled.div`
  a {
    padding: 5px;
    ${hoverOpacity()}
  }
`

export { FooterMenu }
