import styled, { css } from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'

type Props = {
  color?: 'dark' | 'light'
  padded?: boolean
}

const toRGB = (color: 'dark' | 'light' | undefined) =>
  color === 'dark' ? theme.colors.brand : theme.colors.white

const Title = styled.h3<Props>`
  ${theme.typography.Headline1}
  ${(props) =>
    props.padded &&
    css`
      padding: 0 0 40px;

      ${Media.mobile} {
        padding: 0 0 24px;
      }
    `}
  color: ${(p) => toRGB(p.color)};
  text-align: center;
`

Title.defaultProps = {
  color: 'dark',
  padded: false,
}

export { Title }
