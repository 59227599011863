import styled, { css } from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'

type Color = 'dark' | 'light'
type Size = 'small' | 'large'

type Props = {
  color?: Color
  size?: Size
  showIcon?: boolean
}

const renderColorStyle = (color?: Color) => {
  const defaultColor =
    color === 'light' ? theme.colors.white : theme.colors.brand
  const invertColor =
    color === 'light' ? theme.colors.brand : theme.colors.white

  return css`
    border: 3px solid ${defaultColor};
    color: ${defaultColor};

    ${Media.tabletDesktop} {
      :hover {
        background: ${defaultColor};
        color: ${invertColor};
      }
    }
  `
}

const renderArrowIcon = () => css`
  ::after {
    content: ' ';
    display: block;
    width: 22px;
    height: 14px;
    margin-left: 10px;
    background-color: currentColor;
    mask-image: url('/svgs/arrow.svg');
    mask-repeat: no-repeat;
  }
`

const Button = styled.a<Props>`
  ${theme.typography.Button1}
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-height: 45px;
  padding: 12px 12px;
  transition: background 0.2s, color 0.2s, fill 0.2s;
  border-radius: 8px;
  text-align: center;
  ${(props) => props.showIcon !== false && renderArrowIcon()};
  ${(props) => renderColorStyle(props.color)}

  ${Media.desktop} {
    min-height: ${(props) => (props.size === 'small' ? 45 : 60)}px;
    padding: 8px 20px;
  }
`

export { Button }
