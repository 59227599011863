import React from 'react'
import styled, { StyleSheetManager } from 'styled-components'

import theme from '../../theme'
import { hoverOpacity } from '../utils/hoverOpacity'

type Props = {
  className?: string
}

const onClick = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const ScrollToTop: React.FC<Props> = ({ className }: Props) => (
  <StyleSheetManager enableVendorPrefixes>
    <Icon onClick={onClick} className={className} />
  </StyleSheetManager>
)

ScrollToTop.defaultProps = {
  className: '',
}

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 3px solid ${theme.colors.brand};
  border-radius: 27px;
  background-color: ${theme.colors.white};
  cursor: pointer;

  &::before {
    content: ' ';
    display: block;
    width: 22px;
    height: 14px;
    transform: rotate(-90deg);
    background-color: ${theme.colors.brand};
    mask-image: url('/svgs/arrow.svg');
    mask-repeat: no-repeat;
  }

  ${hoverOpacity(0.9)}
`

export { ScrollToTop }
