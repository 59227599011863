import NextLink, { LinkProps } from 'next/link'
import React from 'react'

type Props = LinkProps

const Link: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...otherProps
}) => (
  <NextLink {...otherProps} prefetch={false} legacyBehavior>
    {children}
  </NextLink>
)

export { Link }
