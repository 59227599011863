import { css } from 'styled-components'

import Media from '../utils/Media'
import colors from './colors'

const Headline1 = css`
  font-size: 40px;
  font-weight: 700;
  line-height: 1.25;

  &::after {
    width: 120px;
    height: 8px;
    margin: 24px auto 0;
    content: '';
    display: block;
    background-size: 26px 100%;
    background-image: linear-gradient(
      to right,
      rgb(0, 0, 0) 16px,
      transparent 8px
    );
  }

  ${Media.mobile} {
    font-size: 18px;

    &::after {
      width: 60px;
      height: 4px;
      margin: 8px auto 0;
      background-size: 13px 100%;
      background-image: linear-gradient(
        to right,
        rgb(0, 0, 0) 8px,
        transparent 8px
      );
    }
  }
`

const Headline2 = css`
  color: ${colors.textBlackSecondary};
  font-size: 14px;
  font-weight: 400;

  ${Media.desktop} {
    font-size: 20px;
    line-height: 1.75;
  }
`

const Headline3 = css`
  color: ${colors.textBlackPrimary};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.25;

  ${Media.desktop} {
    font-size: 24px;
  }
`

const Headline4 = css`
  color: ${colors.textBlackSecondary};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  ${Media.desktop} {
    font-size: 20px;
    line-height: 1.25;
  }
`

const Headline5 = css`
  color: ${colors.textBlackPrimary};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
`

const Headline6 = css`
  color: ${colors.textBlackPrimary};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.25;

  ${Media.desktop} {
    font-size: 16px;
  }
`

const Headline7 = css`
  color: ${colors.textBlackPrimary};
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
`

const Headline8 = css`
  color: ${colors.textBlackSecondary};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
`

const Headline9 = css`
  color: ${colors.textBlackPrimary};
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
`

const Subtitle1 = css`
  color: ${colors.textBlackPrimary};
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
`

const Body1 = css`
  color: ${colors.textBlackPrimary};
  font-size: 14px;
  line-height: 1.75;

  ${Media.desktop} {
    font-size: 16px;
  }
`

const Button1 = css`
  color: ${colors.textBlackPrimary};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-decoration: none;
`

const Button2 = css`
  color: ${colors.textBlackPrimary};
  font-size: 10px;
  line-height: 1.25;
`

const Button3 = css`
  color: ${colors.textBlackPrimary};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.25;
`

const Button4 = css`
  color: ${colors.textBlackSecondary};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.48;
`

export default {
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  Headline6,
  Headline7,
  Headline8,
  Headline9,
  Subtitle1,
  Button1,
  Button2,
  Button3,
  Button4,
  Body1,
}
