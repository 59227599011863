import Image from 'next/image'
import { Router } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import useMedia from 'use-media'

import theme from '../../../theme'
import Media, { DESKTOP_WIDTH, MOBILE_WIDTH } from '../../../utils/Media'
import { Button } from '../../elements/Button'
import { Link } from '../../elements/Link'
import { hoverOpacity } from '../../utils/hoverOpacity'
import { Container } from '../Container'
import { Hamburger } from './Hamburger'

const Header: React.FC = () => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false)

  const onRouteChanged = useCallback(() => setMobileMenuActive(false), [])
  useEffect(() => {
    Router.events.on('routeChangeComplete', onRouteChanged)
    Router.events.on('hashChangeComplete', onRouteChanged)
    return () => {
      Router.events.off('routeChangeComplete', onRouteChanged)
      Router.events.off('hashChangeComplete', onRouteChanged)
    }
  }, [onRouteChanged])

  const isMobile = useMedia({ maxWidth: MOBILE_WIDTH })

  return (
    <Wrapper>
      <Container>
        <Inner>
          <Link href="/" passHref>
            <LogoWrapper aria-label="RYDE PASS">
              <LogoImage src="/svgs/rydePass.svg" alt="RYDE PASS" />
            </LogoWrapper>
          </Link>
          <StyledHamburger
            onClick={() => setMobileMenuActive(!mobileMenuActive)}
          />
          <Menu isActive={mobileMenuActive}>
            <MenuItem>
              <Link href="/#about" passHref>
                <MenuItemLink>RYDE PASSについて</MenuItemLink>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link href="/products" passHref>
                <MenuItemLink>商品を探す</MenuItemLink>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link href="/teiki" passHref>
                <MenuItemLink>デジタル定期券</MenuItemLink>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link href="/#inquiry" passHref>
                <MenuItemLink>お問い合わせ</MenuItemLink>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link href="/#appDownload" passHref>
                {isMobile ? (
                  <MenuItemLink>ダウンロード</MenuItemLink>
                ) : (
                  <StyledButton showIcon={false}>ダウンロード</StyledButton>
                )}
              </Link>
            </MenuItem>
          </Menu>
        </Inner>
      </Container>
    </Wrapper>
  )
}

const LogoImage: React.FC<{
  src: string
  alt: string
}> = ({ src, alt }) => {
  const isMobileTablet = useMedia({ maxWidth: DESKTOP_WIDTH })
  return (
    <>
      {isMobileTablet ? (
        <Image src={src} width={40} height={40} alt={alt} />
      ) : (
        <Image src={src} width={60} height={60} alt={alt} />
      )}
    </>
  )
}

const StyledButton = styled(Button)`
  margin: 15px 0;

  ${Media.desktop} {
    width: 128px;
    height: 44px;
    min-height: auto;
    margin: 23px 0;
    padding: 10px 12px;
  }

  ${Media.tablet} {
    margin: 0 10px;
  }
`

const Inner = styled.div`
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
  height: 80px;

  ${Media.desktop} {
    height: 90px;
  }
`

const LogoWrapper = styled.a`
  z-index: 10;

  ${hoverOpacity()}

  ${Media.desktop} {
    min-width: 60px;
    min-height: 60px;
  }
`

const Menu = styled.ul<{ isActive: boolean }>`
  display: block;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  background: transparent;

  ${Media.desktop} {
    display: flex;
    top: 0;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 90px;
    overflow: auto;
    background: transparent;
  }

  ${Media.mobile} {
    display: none;
    position: fixed;
    top: 80px;
    right: 0;
    left: 0;
    height: auto;
    -webkit-overflow-scrolling: touch;
    background: white;

    ${(props) =>
      props.isActive &&
      css`
        display: block;
      `}
  }
`

const MenuItem = styled.li`
  display: inline-block;
  width: auto;
  min-height: 80px;

  ${Media.desktop} {
    width: auto;
    height: 90px;
    padding: 0 15px;
  }

  ${Media.mobile} {
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    min-height: 80px;
    padding: 0;

    border-top: solid 1px ${theme.colors.borderGray};
  }

  &:last-child {
    padding-right: 0;
  }
`

const MenuItemLink = styled.a`
  ${theme.typography.Button1}
  display: inline-flex;
  padding: 30px 15px;
  text-decoration: none;
  ${hoverOpacity()}

  ${Media.desktop} {
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;
  }

  ${Media.mobile} {
    width: 100%;
    padding: 0 30px;
    line-height: 80px;
  }
`

const Wrapper = styled.header`
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
  background: ${theme.colors.white};

  ${Media.desktop} {
    transition: background-color 0.3s;
  }
`
const StyledHamburger = styled(Hamburger)`
  margin-right: -20px;

  ${Media.tabletDesktop} {
    display: none;
  }
`

export { Header }
