import React from 'react'
import styled from 'styled-components'

import theme from '../../../theme'

const lineHeight = 2
const lineSpacing = 4

type Props = {
  className?: string
  // eslint-disable-next-line no-undef
  onClick: React.MouseEventHandler<HTMLAnchorElement>
}

const Hamburger: React.FC<Props> = ({ className, onClick }: Props) => (
  <Burger onClick={onClick} className={className} aria-label="メニュー">
    <BurgerBox>
      <BurgerInner color="dark" />
    </BurgerBox>
  </Burger>
)

Hamburger.defaultProps = {
  className: '',
}

const Burger = styled.a`
  display: inline-block;
  margin: 0;
  padding: 20px;
  overflow: visible;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: inherit;
  font: inherit;
  text-transform: none;
  cursor: pointer;
`

const BurgerBox = styled.div`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
`

const BurgerInner = styled.div`
  top: 50%;
  margin-top: ${(lineHeight / 2) * -1}px;

  &:before,
  &:after {
    content: '';
    display: block;
  }
  &,
  &:before,
  &:after {
    position: absolute;
    width: 100%;
    height: ${lineHeight}px;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    background-color: ${theme.colors.brand};
  }
  &:before {
    top: ${(lineSpacing + lineHeight) * -1}px;
  }
  &:after {
    bottom: ${(lineSpacing + lineHeight) * -1}px;
  }
`

export { Hamburger }
