export default {
  brand: 'rgba(0,0,0,1)',
  white: 'rgba(255,255,255,1)',
  textBlackPrimary: 'rgba(0,0,0,1)',
  textBlackSecondary: 'rgba(0,0,0,0.6)',
  textBlackTertiary: 'rgba(0,0,0,0.4)',
  textWhitePrimary: 'rgba(255,255,255,1)',
  textWhiteSecondary: 'rgba(255,255,255,0.6)',
  borderWhite: 'rgba(255,255,255,0.2)',
  bgThinGray: 'rgba(243, 243, 243, 1)',
  bgGray: 'rgba(240, 240, 240, 1)',
  bgGrayInner: 'rgba(242, 242, 242, 1)',
  bgWhite: 'rgba(255,255,255,1)',
  borderGray: 'rgba(0, 0, 0, 0.2)',
}
